var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'GroupScheduleExamInfo',"size":"xl","headerText":_vm.$t('GroupScheduleExams.data'),"headerIcon":_vm.groupScheduleExam.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.fullCode,"title":_vm.$t('GroupScheduleExams.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.groupScheduleExamNameAr,"title":_vm.$t('GroupScheduleExams.nameAr'),"imgName":'GroupScheduleExams.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.groupScheduleExamNameEn,"title":_vm.$t('GroupScheduleExams.nameEn'),"imgName":'GroupScheduleExams.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.groupScheduleExamNameUnd,"title":_vm.$t('GroupScheduleExams.nameUnd'),"imgName":'GroupScheduleExams.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.groupScheduleExamDescriptionAr,"title":_vm.$t('GroupScheduleExams.descriptionAr'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.groupScheduleExamDescriptionEn,"title":_vm.$t('GroupScheduleExams.descriptionEn'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.groupScheduleExamDescriptionUnd,"title":_vm.$t('GroupScheduleExams.descriptionUnd'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.groupScheduleExamNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
          _vm.groupScheduleExam.startExamDate,
          _vm.groupScheduleExam.startExamTime
        ),"title":_vm.$t('from'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
          _vm.groupScheduleExam.endExamDate,
          _vm.groupScheduleExam.endExamTime
        ),"title":_vm.$t('to'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.durationCurrent,"title":_vm.$t('general.duration'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.educationalGroupInfoData.educationalGroupNameCurrent,"title":_vm.$t('EducationalGroups.name'),"imgName":'EducationalGroups.svg'}}),_c('div',{staticClass:"my-card col-12"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("SimpleExamModels.data")))]),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.groupScheduleExam.simpleExamModelInfoData
              .simpleExamModelTitleCurrent,"title":_vm.$t('SimpleExamModels.title'),"imgName":'SimpleExamModels.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.simpleExamModelInfoData.examTypeNameCurrent,"title":_vm.$t('ConstantsListSelect.ExamTypesName'),"imgName":'ExamTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.groupScheduleExam.simpleExamModelInfoData.simpleExamModelDegree,"title":_vm.$t('SimpleExamModels.examDegree'),"imgName":'number.svg'}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }