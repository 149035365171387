var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row custom-cards"},_vm._l((_vm.groupScheduleExamsData),function(groupScheduleExam,index){return _c('CustomCard',{key:index,attrs:{"className":'col-sm-6 col-md-4 col-lg-3',"title":groupScheduleExam.simpleExamModelInfoData.simpleExamModelTitleCurrent,"description":groupScheduleExam.fullCode,"imagePath":groupScheduleExam.groupScheduleExamImagePath,"defaultImg":_vm.defaultImg,"index":++index + _vm.filterData.currentIndex}},[(_vm.checkPrivilege(_vm.hasStudentScheduleExamTimeReport()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('general.print')},on:{"click":function($event){_vm.setGroupScheduleExamData(groupScheduleExam);
          _vm.openBottomSheet('GroupScheduleExamReport');}}},[_c('img',{attrs:{"src":require("@/assets/images/print.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasEducationalGroup()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'EducationalGroups',
          params: {
            educationalGroupToken: groupScheduleExam.educationalGroupToken,
          },
        },"title":_vm.$t('EducationalGroups.data')}},[_c('img',{attrs:{"src":require("@/assets/images/EducationalGroups.svg")}})])],1):_vm._e(),(_vm.checkPrivilege(_vm.hasSimpleExamModel()))?_c('li',[_c('router-link',{attrs:{"to":{
          name: 'SimpleExamModels',
          params: {
            simpleExamModelToken: groupScheduleExam.simpleExamModelToken,
          },
        },"title":_vm.$t('SimpleExamModels.data')}},[_c('img',{attrs:{"src":require("@/assets/images/SimpleExamModels.svg")}})])],1):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('info')},on:{"click":function($event){_vm.setGroupScheduleExamData(groupScheduleExam);
          _vm.openBottomSheet('GroupScheduleExamInfo');}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),_c('li',[_c('button',{attrs:{"title":_vm.$t('general.qrCode')},on:{"click":function($event){_vm.setGroupScheduleExamData(groupScheduleExam);
          _vm.openBottomSheet('GroupScheduleExamQRCode');}}},[_c('img',{attrs:{"src":require("@/assets/images/qr-code.svg")}})])]),(_vm.checkPrivilege(_vm.hasGroupScheduleExamEdit()))?_c('li',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){_vm.setGroupScheduleExamData(groupScheduleExam);
          _vm.openBottomSheet('GroupScheduleExamUpdate');}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.checkPrivilege(_vm.hasGroupScheduleExamFinaleDelete()))?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.GroupScheduleExamDelete",modifiers:{"GroupScheduleExamDelete":true}}],attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setGroupScheduleExamData(groupScheduleExam)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),_c('li',[_c('button',{attrs:{"title":_vm.$t('actionsData.modelName')},on:{"click":function($event){_vm.setGroupScheduleExamData(groupScheduleExam);
          _vm.openBottomSheet('ActionsData');}}},[_c('img',{attrs:{"src":require("@/assets/images/actions-data.svg")}})])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }